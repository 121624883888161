import axios from 'axios'
import store from '../store'

import {PUBLIC_MODULE} from './_prefix'

export const login = (email, password) => {
  return axios.post(`${PUBLIC_MODULE}/login`,
    {

      email: email,
      password: password

    }, {
      headers: {
        Authorization: store.state.token
      }
    })
}

export const register = (username, email, password, verify) => {
  return axios.post(`${PUBLIC_MODULE}/register`,

    {

      name: username,
      email: email,
      password: password,
      verificationCode: verify

    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const sendEmail = (email, code, id) => {
  return axios.post(`${PUBLIC_MODULE}/sendEmail`,
    {
      email: email,
      verifyCode: code,
      verifyCodeId: id
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const resetPassword = (verificationCode, password, email) => {
  return axios.post(`${PUBLIC_MODULE}/passwordReset`,
    {
      verificationCode: verificationCode,
      password: password,
      email: email
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  })
}

export const passwordEmail = (email, code, id) => {
  return axios.post(`${PUBLIC_MODULE}/passwordEmail`, {
    email: email,
    verifyCode: code,
    verifyCodeId: id
  }, {
    headers: {
      Authorization: store.state.token
    }
  }).then(res => {
    return res.data
  })
}

export const verifyImg = () => {
  return axios.get(`${PUBLIC_MODULE}/getVerifyCodeImg?time=` + new Date(),
    {
      headers: {
        Authorization: store.state.token
      },
      responseType: 'arraybuffer'
    }
  )
}
export const getRecentText = () => {
  return axios.get(`${PUBLIC_MODULE}/recentText`).then(res => {
    return res.data
  })
}
